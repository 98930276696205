import "./ErrorLayer.css";

const ErrorLayer: React.FC<{ errorType : string, errorDetail: string | undefined,
    period: string | undefined, startTime: string | undefined, endTime: string | undefined}> = (props) => {
    return(
        <p>
            {(() => {
                switch (props.errorType) {
                    case 'EXPIRED':
                        return "This LiveShare link has expired."
                    case 'OUT_OF_HOURS':
                        return "This service is currently unavailable. " + setErrorTimezone(props.period!,props.startTime!,props.endTime!)
                    case 'NOT_FOUND':
                        return "This LiveShare link is invalid."
                    default:
                        return "Something went wrong..."
                }
            })()}
        </p>
    );
};

function setErrorTimezone(period : string, startTime: string, endTime: string) {
    const startTimeLocal = convertUTCToLocal(startTime, period);
    const endTimeLocal = convertUTCToLocal(endTime, period);

    // format the output string
    const [time1Value] = startTimeLocal.split(' ');
    return `Operational Hours: ${period} ${time1Value} - ${endTimeLocal}`;
}

function convertUTCToLocal(date : string, day : string) {
    const nextDate = getNextDate(day);

    const dateLocal = new Date(`${nextDate} ${date} UTC`);

    return dateLocal.toTimeString();
}

function getNextDate(day : string){
    let targetDay;
    switch (day) {
        case 'SUNDAY':
            targetDay = 0;
            break;
        case 'MONDAY':
            targetDay = 1;
            break;
        case 'TUESDAY':
            targetDay = 2;
            break;
        case 'WEDNESDAY':
            targetDay = 3;
            break;
        case 'THURSDAY':
            targetDay = 4;
            break;
        case 'FRIDAY':
            targetDay = 5;
            break;
        case 'SATURDAY':
            targetDay = 6;
            break;
        case 'WEEKENDS':
            targetDay = 6;
            break;
        case 'WEEKDAYS':
            targetDay = 1;
            break;
        default:
            targetDay = 0;
            break;
    }
    const currentDate = new Date();

    const daysUntilTargetDayOfWeek = (targetDay - currentDate.getDay() + 7) % 7;

    const nextDate = new Date(currentDate.getTime() + daysUntilTargetDayOfWeek * 86400000); // 86400000 milliseconds = 1 day

    return nextDate.toDateString();
}

export default ErrorLayer;