import L from "leaflet";
import { Fragment, useCallback, useEffect } from "react";
import { useMap } from "react-leaflet";
import Asset from "../../models/Asset";
import AssetMarker from "./AssetMarker";

const AssetsLayer: React.FC<{assetList : Asset[]}> = (props) => {

  const map = useMap();

  // Gets an array of lat lng arrays and fits the map to bounds
  const getAssetBounds = useCallback(() => {
    const assetBounds = props.assetList.map(asset => asset.location.coordinates)
    .map(coordinate => [coordinate.latitude, coordinate.longitude]);
    var bounds = new L.LatLngBounds( assetBounds as L.LatLngBoundsLiteral);
    map.fitBounds(bounds);
  }, [props.assetList, map]);

  useEffect(() => {
    getAssetBounds();
  }, [props.assetList, map, getAssetBounds]);

  const isValidData = (asset: Asset) => {
    return asset?.location?.address 
    && asset?.location?.coordinates?.latitude 
    && asset?.location?.coordinates?.longitude;
  };

  const markers = props.assetList.map((asset: Asset) => {
    return isValidData(asset) 
    && <AssetMarker displayName={asset.displayName} 
      colour={asset.colour} 
      location={asset.location}/>
  });

  return(
      <Fragment>
          {markers}
      </Fragment>
  );
};

export default AssetsLayer;
