import { Fragment, useEffect, useState } from "react";
import { MapContainer, TileLayer} from "react-leaflet";
import Asset from "../models/Asset";
import AssetsLayer from "./asset/AssetsLayer";
import PropertiesLoader from "../properties/PropertiesLoader";
import ExceptionModel from "../models/Exception";
import ErrorLayer from "./error/ErrorLayer";

import "./LiveShareApp.css";

const properties = PropertiesLoader();

const LiveShareApp: React.FC<{}> = () => {

  const [assets, setAssets] = useState<Asset[]>([]);
  const [exception, setExcption] = useState<ExceptionModel>({} as ExceptionModel);
  const [error, setError] = useState<boolean>(false);

  async function fetchAssetInformation() {
    const urlParams = new URLSearchParams(window.location.search);
    await fetch(properties.platformApiEndpoint + "/" + urlParams.get('token'))
    .then(async response => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson ? await response.json() : null;

      // check for error response
      if (!response.ok) {
        // get error message from body
        const error = data;
        return Promise.reject(error);
      }

      const assetsData = data.assets.map((asset : Asset) => {
        return {
          displayName: asset.displayName,
          colour: asset.colour,
          location: asset.location
        };
      });
      setAssets(assetsData);
      setError(false);

    })
    .catch(error => {
      setError(true);
      setExcption(new ExceptionModel(error.errorMessage, error.errorType, error.httpStatusCode, error.uuid, error.errorDetail, error.period, error.startTime, error.endTime));
  });
    
  }

  useEffect(() => {
    fetchAssetInformation();
    let interval = setInterval(fetchAssetInformation, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Fragment>
      {!error && assets && assets.length >= 1 && 
        <MapContainer center={[53.77653, -1.51546]} zoom={13} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <AssetsLayer assetList={assets}/>
        </MapContainer> 
      }
      {error && <ErrorLayer errorType={exception.errorType} errorDetail={exception.errorDetail}
                            period={exception.period} startTime={exception.startTime} endTime={exception.endTime}/>}
  </Fragment>
}

export default LiveShareApp;